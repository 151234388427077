import React from 'react';
import './Song.css';

function Song(props) {
    return ( 
        <div className='musicContainer'>
            <div className='left fastFade'>
                <h3 className='musicHeading'>{props.heading}</h3>
                <p className='musicBody'> <b>Contributions:</b> {props.contributions}</p>
                <p className='musicBody'> <b>Released:</b> {props.releaseDate}</p>
            </div>
            <div className='right slowFade'>
                <iframe src={props.source} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;picture-in-picture" allowfullscreen></iframe>
            </div>
            
            
        </div> 
    );
}

export default Song;