import "./TextCard.css"

function TextCard(props){
    return(
        <div className="Card">
            <div className={`text ${props.className}`}>
                <span className="heading">{props.heading}</span><br/>
                <span className="body">{props.children}</span>
            </div>
        </div>
    );
}

export default TextCard;