import React, { useState } from 'react';
import "./App.css";
import './scrollbar.css';
import TextCard from "./Components/TextCard";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import ButtonBar from "./Components/ButtonBar";
import Song from './Components/Song';


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyADAdslrS3LGqArRR3616m0syJMXLy8Kbg",
  authDomain: "propane-flag-359821.firebaseapp.com",
  projectId: "propane-flag-359821",
  storageBucket: "propane-flag-359821.appspot.com",
  messagingSenderId: "76878953716",
  appId: "1:76878953716:web:3c29e85af603aa9cd772a1",
  measurementId: "G-TB1TLRN640"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


function App() {

    let yellowPurple = {background: 'linear-gradient(-45deg, rgb(114, 108, 54)20%, rgb(91, 46, 102) 80%)'};
    let blueOrange = {background: 'linear-gradient(-45deg, rgb(47, 52, 100)20%, rgb(102, 76, 46) 80%)'};
    let redGreen = {background: 'linear-gradient(-45deg, rgb(100, 47, 47)20%, rgb(46, 102, 57) 100%)'};

    const music='MusicPage', about='AboutPage', cs='CSPage';
    const [page, setPage] = useState(about);
    const [back, setBack] = useState(yellowPurple);

    

    function aboutHandler(){
        setPage(about);
        setBack(yellowPurple);
    }
    function csHandler(){
        setPage(cs);
        setBack(blueOrange);
    }
    function musicHandler(){
        setPage(music);
        setBack(redGreen);
    }

    return (
        <div className="App">

            <div className="container" style={back}>
                <ButtonBar onAbout={aboutHandler} onCS={csHandler} onMusic={musicHandler}/>
                {/* about me section    */}
                {page===about && <div className="about section">
                    <TextCard className='fastFade' heading="Why, hello there!">
                        My name's Jack Hickey and I'm an NUI Maynooth graduate,
                        majoring in computer science and music technology. <br/><br/>
                        Email: jackhickey9@gmail.com
                    </TextCard>

                    <TextCard className='slowFade'>
                        <img id="aboutImage" src="Me.jpg" alt="Headshot" />
                    </TextCard>
                </div>}
                
                {page===cs && <div className="cs section">
                    <TextCard className='fastFade' heading="Comp Sci Skills">
                        Throughout my degree, I have gained experience using many coding
                        languages, including →<br />
                    </TextCard>

                    <TextCard className='slowFade' heading='Languages:'>
                        <p>
                            • Java
                            <br />• HTML (+ pug)
                            <br />• CSS
                            <br />• Javascript (+ NodeJS, ReactJS)
                            <br />• SQL databases (e.g. mySQL, &nbsp;&nbsp;&nbsp;&nbsp; Postgresql)
                            <br />• NoSQL databases (e.g. &nbsp;&nbsp;&nbsp;&nbsp; MongoDB)
                        </p>
                    </TextCard>
                </div>}

                {page===music && <div className='music section'>
                    <Song 
                        heading={'J.O.E.L - Summer of Sand (Single)'} 
                        contributions={'mixing, mastering'} 
                        releaseDate={'7 July 2022'} 
                        source={'https://www.youtube.com/embed/blxCdo9i-KE'}/> 
                    <Song 
                        heading={'L For Left - Small (4 Song EP)'} 
                        contributions={'producer, recording, mixing, mastering, guitars, vocals'} 
                        releaseDate={'21 September 2020'} 
                        source={'https://www.youtube.com/embed/videoseries?list=OLAK5uy_lbjV2yUbK5u0jWHETK2AFfTQkA4lGck-c'}/>
                    <Song 
                        heading={'L For Left - Next Stop (4 Song EP)'} 
                        contributions={'producer, recording, mixing, mastering, guitars, vocals'} 
                        releaseDate={'1 March 2020'} 
                        source={'https://www.youtube.com/embed/videoseries?list=OLAK5uy_niwNNbLJz0GB7VXfCPfOqyY_P-3yg9UQ8'}/> 
                </div>}
            </div>
        </div>
    );

    
}
export default App;
