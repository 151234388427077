import React from 'react';
import './ButtonBar.css';

function ButtonBar(props) {

    

    return ( 
        <div className='buttonBar'>
            <div className="buttonContainer">
                <button onClick={props.onCS}>Computer Science</button>
            </div>

            <div className="buttonContainer">
                <button onClick={props.onAbout}>About Me</button>
            </div>

            <div className="buttonContainer">
                <button onClick={props.onMusic}>Music Production</button>
            </div>
        </div>
     );
}

export default ButtonBar;